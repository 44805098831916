import fetch from 'node-fetch';
import Cookie from 'js-cookie';

export async function fetchProfile({}, cb = () => {}) {
    const response = await fetch(`/api/secure/profile`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};