import { useNavigate } from "react-router";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { fetchLogout } from "../../network/LogoutNetwork"

function Logout() {

	const navigate = useNavigate();

    fetchLogout({}, (err, res) => {});

    useEffect(() => {
        Cookies.remove("access_token");
        navigate("/login")
})

}
export default Logout;
