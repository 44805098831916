import Router from "./Router";
import store from './store/Store';
import { Provider } from 'react-redux';

function App() {
    return (
        <Provider store={store}>
            <Router />
        </Provider>
    );
}

export default App;
