import { Container, Row, Col, Table} from "react-bootstrap";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { addCallList } from "../../store/reducers/call";
import { fetchCallList } from "../../network/CallNetwork";
import Breadcrumb from "../breadcrumb";
import Header from "../header";

function CallsList() {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const Call = useSelector((state) => state.call);

	useEffect(() => {
        getCallList();
    },[]);

	const openDetail = (e, call_id) => {
		e.preventDefault();
		navigate(`/${call_id}`);
	}

	const getCallList = () => {
		fetchCallList({limit : 200, offset : 0}, (err, resp) => {
			if (!err) {
				dispatch(addCallList(resp));
			}
		});
	}

	const mappedCallList = Call.callList.map( (el) => 
		<tr key={el.call_id}>
			<td><a href="#" onClick={(e) => {openDetail(e,el.call_id)}}><i className="bi bi-pencil-square"> </i></a></td>
			<td>{ dayjs(el.created_on).format('DD.MM.YYYY') }</td>
			<td>{ dayjs(el.created_on).format('HH:mm:ss') }</td>
			<td>{ el.call_type }</td>
			<td>{ el.cid }</td>
			<td>{ el.did }</td>
			<td>{ el.dial_status }</td>
		</tr>);

    return (
		<Container>
		<Row>
			<Col>
				<Header />
			</Col>
		</Row>

		<Row style={ {marginTop: '10px'} }>
            <Col>
                <Breadcrumb 
                    items={[
                        {url:"/", name: "Главный экран"},
                        {url:"", name: "Звонки"}
                    ]}
                />
            </Col>
        </Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
				<h1>Звонки</h1>
			</Col>
		</Row>
		<Row style={{marginTop: "20px"}}>
			<Col>
				<Table striped bordered>
					<thead>
						<tr>
							<th></th>
							<th>Дата</th>
							<th>Время</th>
							<th>Тип звонка</th>
							<th>Номер Жителя</th>
							<th>Номер УК</th>
							<th>Статус Звонка</th>
						</tr>
					</thead>
					<tbody>
						{mappedCallList}
					</tbody>
				</Table>
			</Col>
		</Row>
		</Container>
    );
}

export default CallsList;
