import fetch from 'node-fetch';
import Cookie from "js-cookie";

export async function fetchCallList({ limit, offset }, cb = () => {}) {
    const response = await fetch(`/api/secure/call?limit=${limit}&offset=${offset}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function fetchCallDetail({ call_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/call/${call_id}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function fetchCallDetailList({ call_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/call/${call_id}/detail_list`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function fetchRecCall({ call_id, rec_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/call/${call_id}/detail_list/${rec_id}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}