import fetch from 'node-fetch';
import Cookie from 'js-cookie';

export async function fetchLogout({ }, cb = () => {}) {
    const response = await fetch("/api/secure/logout", {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}