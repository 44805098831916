import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { postLogin } from "../../network/LoginNetwork";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

function Login() {

	const navigate = useNavigate();

	const checkPin = (e) => {
		e.preventDefault();
		postLogin({pin : e.target.formBasicPassword.value, login : e.target.formBasicLogin.value}, (err, resp) => {
			if (err) {
				alert('Неверный логин или пароль');
			} else {
				Cookies.set("access_token", resp.access_token);
				navigate("/");
			}
		})

	}

	return (
		<Container style={{marginTop: "80px"}}>
			<Row className="justify-content-md-center">
				<Col xs={4}>
					<form onSubmit={checkPin}>
					<Form.Group className="mb-3" controlId="formBasicLogin">
						<Form.Label>Номер телефона</Form.Label>
						<Form.Control type="text" placeholder="Номер телефона" />
						<Form.Text className="text-muted">
						Здесь подсказочка
						</Form.Text>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Label>Пин код</Form.Label>
						<Form.Control type="password" placeholder="Пин код" />
					</Form.Group>
					<Button variant="primary" type="submit">
						Войти
					</Button>
					</form>
				</Col>
			</Row>
		</Container>
  	);
}

export default Login;
