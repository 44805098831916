import fetch from 'node-fetch';

export async function postLogin({ pin, login }, cb = () => {}) {
    const response = await fetch("/api/login", {
        method : "POST",
        body : JSON.stringify({ pin, login }),
        headers : {
            'Content-Type' : 'application/json'
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        cb(response.status + " " + response.statusText)
    }
}