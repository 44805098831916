import { createSlice } from '@reduxjs/toolkit'

export const callSlice = createSlice({
    name: 'Call',
    initialState: {
        call : {},
        callList : [],
        callDetailList : [],
    },
    reducers: {
        addCallList : (state, action) => {
            state.callList = (action.payload);
        },
        addCall : (state, action) => {
            state.call = (action.payload);
        },
        addCallDetailList : (state, action) => {
            state.callDetailList = (action.payload);
        },
        clearCallList: (state,action) => {
            state.callList = [];
        },
        callDetailList: (state,action) => {
            state.callDetailList = [];
        }
    }
});

export const { 
    addCallList, 
    addCall, 
    addCallDetailList, 
    clearCallList, 
    callDetailList} = callSlice.actions;

export default callSlice.reducer