import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Navbar } from "react-bootstrap";
import { fetchProfile } from "../../network/ProfileNetwork";
import { addUser } from '../../store/reducers/user';

function Header() {

    const dispatch = useDispatch();
    const User = useSelector((state) => state.user);
    const userLogin = User.user.login;

    useEffect(() => {
            getUserProfile();    
    },[]);

    const getUserProfile = () => {
		fetchProfile({  }, (err, resp) => {
			if (!err) {
				dispatch(addUser(resp));
			}
		});
	}

	return (
		<Navbar className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/">Cactus</Navbar.Brand>
                    <Navbar.Toggle />
                        <Navbar.Collapse>
                            <Navbar.Text>
                                <b style={{color : 'green'}}>Добро пожаловать, {userLogin}!</b>
                            </Navbar.Text>
                        </Navbar.Collapse>

                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                <a href="/logout">Выйти</a>
                            </Navbar.Text>
                        </Navbar.Collapse>
            </Container>
        </Navbar>
  	);
}

export default Header;
